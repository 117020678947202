import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { WistiaPlayer } from '~/components/wistia-player/Components/WistiaPlayer';
import { WistiaPlayerThumbnail } from '~/components/wistia-player/Components/WistiaPlayerThumbnail';
import { createWistiaPlayerStore, WistiaPlayerStore } from '~/components/wistia-player/Stores/WistiaPlayer.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { Media } from '~/media-set/Models/Media.model';

interface IProps {
	mediaModel?: Media;
	showMediaPlayer: boolean;
}

export const MediaVideoWistia = observer((props: IProps) => {
	const {
		mediaModel: {
			fileName = '',
		} = {},
		showMediaPlayer = false,
	} = props;
	const { linkEventStore } = useGlobalContext();
	const [store] = useState<WistiaPlayerStore>(() => createWistiaPlayerStore(fileName, linkEventStore));

	if (showMediaPlayer) {
		return (
			<div className={'sm:tw-h-[240px]'}>
				<WistiaPlayer
					className={'tw-absolute md:tw-max-w-[calc(100%-6px)] sm:tw-top-[50%] sm:tw-translate-y-[-50%]'}
					store={store}
					videoId={store.videoId}
				/>
			</div>
		);
	}

	return (
		<WistiaPlayerThumbnail
			duration={store.duration}
			url={store.thumbnail}
			videoId={store.videoId}
			onHasData={media => store.setMedia(media)}
		/>
	);
});
